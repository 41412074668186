// Generated by Framer (a10584e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-b7Ui8"

const variantClassNames = {Jgb9FE3pD: "framer-v-1hxo978"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, ZceSIG0ZG: link ?? props.ZceSIG0ZG} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ZceSIG0ZG, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Jgb9FE3pD", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={ZceSIG0ZG} motionChild nodeId={"Jgb9FE3pD"} openInNewTab={false} scopeId={"mXXXGvXYk"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-1hxo978", className, classNames)} framer-iwkngq`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Jgb9FE3pD"} ref={refBinding} style={{...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 97, intrinsicWidth: 97, loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 0)), pixelHeight: 65, pixelWidth: 94, src: "https://framerusercontent.com/images/RVpMw7ZcNBEqJKeNYmwCoVvjk.png"}} className={"framer-8jpe2c"} data-framer-name={"Logo (Replace)"} layoutDependency={layoutDependency} layoutId={"qY_xT0BFS"}/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-b7Ui8.framer-iwkngq, .framer-b7Ui8 .framer-iwkngq { display: block; }", ".framer-b7Ui8.framer-1hxo978 { height: 24px; overflow: visible; position: relative; text-decoration: none; width: 24px; }", ".framer-b7Ui8 .framer-8jpe2c { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ZceSIG0ZG":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermXXXGvXYk: React.ComponentType<Props> = withCSS(Component, css, "framer-b7Ui8") as typeof Component;
export default FramermXXXGvXYk;

FramermXXXGvXYk.displayName = "Elements/Logo";

FramermXXXGvXYk.defaultProps = {height: 24, width: 24};

addPropertyControls(FramermXXXGvXYk, {ZceSIG0ZG: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramermXXXGvXYk, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})